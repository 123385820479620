/* eslint-disable no-console */
import Toastify from 'toastify-js';
import { getShowDebugInfo } from './localstorage/debug.js';

const commonOptions: Toastify.Options = {
  close: true,
  duration: 3000,
  position: 'right',
  // stopOnFocus: true,
};

const ToastifyWrapper = (options: Toastify.Options, log?: { logLevel: 'error' | 'warn' | 'info', error?: Error }, override?: boolean) => {
  // Only show toast if debug info is enabled in localstorage
  if (log) {
    switch (log.logLevel) {
      case 'error':
        console.error(log.error || options.text);
        break;
      case 'warn':
        console.warn(options.text);
        break;
      default:
        console.info(options.text);
        break;
    }
  }

  if (getShowDebugInfo() || override) {
    Toastify(options).showToast();
    return true;
  }
  return false;
};

export const infoToast = (text: string, override?: boolean, duration?: number) => {
  ToastifyWrapper({
    text,
    className: 'info-toast',
    ...commonOptions,
    duration: duration || commonOptions.duration,
  }, undefined, override);
};

export const logInfoToast = (text: string) => {
  ToastifyWrapper({
    text,
    className: 'info-toast',
    ...commonOptions,
  }, { logLevel: 'info' });
};

export const warningToast = (text: string, override?: boolean, duration?: number) => {
  ToastifyWrapper({
    text,
    className: 'warning-toast',
    ...commonOptions,
    duration: duration || commonOptions.duration,
  }, undefined, override);
};

export const logWarningToast = (text: string) => {
  ToastifyWrapper({
    text,
    className: 'warning-toast',
    ...commonOptions,
  }, { logLevel: 'warn' });
};

export const errorToast = (text: string, override?: boolean, duration?: number) => {
  ToastifyWrapper({
    text,
    className: 'error-toast',
    ...commonOptions,
    duration: duration || commonOptions.duration,
  }, undefined, override);
};

export const logErrorToast = (error?: Error, text?: string) => {
  ToastifyWrapper({
    text: text ? `${text}. Error: ${error?.message}` : error?.message,
    className: 'error-toast',
    ...commonOptions,
  }, { logLevel: 'error', error });
};
