import { infoToast } from '../toast.js';
import { getLocalstorageItem, setLocalstorageItem } from './localstorage.js';

const DEBUG_INFO_KEY = 'DebugInfo';

export const setShowDebugInfo = (value: boolean) => {
  setLocalstorageItem(DEBUG_INFO_KEY, value.toString());

  const debugNode = document.getElementById('debug');
  if (debugNode) {
    debugNode.className = value ? 'visible text-danger' : 'invisible';
  }

  infoToast(value ? 'Debug info enabled' : 'Debug info disabled', true);
};

export const getShowDebugInfo = (): boolean => {
  const item = getLocalstorageItem(DEBUG_INFO_KEY);

  return item === 'true';
};
