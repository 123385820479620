import { errorToast, infoToast, warningToast } from '../toast.js';
import { getLocalstorageObject, removeLocalstorageItem, setLocalstorageObject } from './localstorage.js';

// This is used to set notifications to be shown when page reloads / changing the url to not lose the notification (ex. user is timed out)

const NOTIFICATION_KEY = 'notification';

export type Notification = {
  text: string,
  type: 'warning' | 'info' | 'error'
};

export const getAndShowNotification = () => {
  const item = getLocalstorageObject<Notification>(NOTIFICATION_KEY);

  if (item) {
    switch (item.type) {
      case 'warning':
        warningToast(item.text, true);
        break;
      case 'info':
        infoToast(item.text, true);
        break;
      case 'error':
        errorToast(item.text, true);
        break;
      default:
        break;
    }
    removeLocalstorageItem(NOTIFICATION_KEY);
  }
};

export const setNotification = (notification: Notification) => {
  setLocalstorageObject(NOTIFICATION_KEY, notification);
};
