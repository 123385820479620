// import { Logging } from '@google-cloud/logging';

export async function logEntry(message: string): Promise<void> {

  /*
    const logging = new Logging({
        projectId: 'my-project-id',
        keyFilename: '/path/to/keyfile.json',
    });

    const log = logging.log('my-log');
    const metadata = {
        severity: 'INFO',
    };

    const entry = log.entry(metadata, message);

    await log.write(entry);
    */
}
