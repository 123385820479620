type Key = string;
type Value = string;

function openDatabase(): Promise<IDBDatabase> {
  return new Promise((resolve, reject) => {
    const request = window.indexedDB.open('CPproDB');

    request.onerror = () => reject(new Error('Failed to open database'));

    request.onsuccess = () => resolve(request.result);

    request.onupgradeneeded = () => {
      const db = request.result;

      db.createObjectStore('storage');
    };

    request.onblocked = () => {
      reject(new Error('Database is blocked'));
    };
  });
}

export async function getData(key: Key): Promise<Value | undefined> {
  const db = await openDatabase();
  const transaction = db.transaction(['storage'], 'readonly');
  const objectStore = transaction.objectStore('storage');
  const getRequest = objectStore.get(key);
  const requestResult = await new Promise<IDBRequest<Value>>((resolve, reject) => {
    getRequest.onerror = () => reject(new Error('Failed to get data'));
    getRequest.onsuccess = () => resolve(getRequest);
  });
  const data = requestResult.result;

  db.close();

  return data;
}

export async function setData(key: Key, value: Value): Promise<void> {
  const db = await openDatabase();
  const transaction = db.transaction(['storage'], 'readwrite');
  const objectStore = transaction.objectStore('storage');
  const getRequest = objectStore.get(key);
  const exists = Boolean(await getRequest);
  const putRequest = objectStore.put(value, key);
  await putRequest;

  db.close();

  if (!exists) {
    // eslint-disable-next-line no-promise-executor-return
    await new Promise((resolve) => setTimeout(resolve, 0));
  }
}

export async function deleteData(key: Key): Promise<void> {
  const db = await openDatabase();
  const transaction = db.transaction(['storage'], 'readwrite');
  const objectStore = transaction.objectStore('storage');
  const deleteRequest = objectStore.delete(key);
  await deleteRequest;
  db.close();
}

export async function deleteAllData(): Promise<void> {
  const db = await openDatabase();
  const transaction = db.transaction(['storage'], 'readwrite');
  const objectStore = transaction.objectStore('storage');
  const clearRequest = objectStore.clear();
  await clearRequest;
  db.close();
}
