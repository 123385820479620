// Inspired by code from: https://stackoverflow.com/questions/14672746/how-to-compress-an-image-via-javascript-in-the-browser/44849182#44849182
// EXIF-parsing / reader did not work here so we are using default toBlob with quality.
function imgToCanvasWithOrientation(img: CanvasImageSource, rawWidth: number, rawHeight: number, orientation?: number) {
  const canvas = document.createElement('canvas');
  if (orientation && orientation > 4) {
    canvas.width = rawHeight;
    canvas.height = rawWidth;
  } else {
    canvas.width = rawWidth;
    canvas.height = rawHeight;
  }

  if (orientation && orientation > 1) {
    console.log(`EXIF orientation = ${ orientation }, rotating picture`);
  }

  const ctx = canvas.getContext('2d');
  // eslint-disable-next-line default-case
  switch (orientation) {
    case 2: ctx?.transform(-1, 0, 0, 1, rawWidth, 0); break;
    case 3: ctx?.transform(-1, 0, 0, -1, rawWidth, rawHeight); break;
    case 4: ctx?.transform(1, 0, 0, -1, 0, rawHeight); break;
    case 5: ctx?.transform(0, 1, 1, 0, 0, 0); break;
    case 6: ctx?.transform(0, 1, -1, 0, rawHeight, 0); break;
    case 7: ctx?.transform(0, -1, -1, 0, rawHeight, rawWidth); break;
    case 8: ctx?.transform(0, -1, 1, 0, 0, rawWidth); break;
  }
  ctx?.drawImage(img, 0, 0, rawWidth, rawHeight);
  return canvas;
}
/**
 * Other options:
 *
 * From EOP:
 * https://github.com/hMatoba/piexifjs
 * https://github.com/mattiasw/ExifReader
 *
 * From me:
 * https://www.npmjs.com/package/compressorjs?activeTab=readme
 * https://www.npmjs.com/package/browser-image-compression
 *
 * ---
 * wasm: https://github.com/kleisauke/wasm-vips
 * https://github.com/jamsinclair/jSquash
 *
 * https://jimp-dev.github.io/jimp/guides/getting-started/
 */
export const imageCompressor = async (
  file: File,
  acceptFileSize: number,
  maxWidth: number,
  maxHeight: number,
  quality: number,
): Promise<File> => {
  if (file.size <= acceptFileSize) {
    return file;
  }
  const promise = new Promise<File>((resolve) => {
    const img = new Image();
    img.onerror = () => {
      resolve(file);
    };
    img.onload = () => {
      let w = img.width;
      let h = img.height;
      const scale = Math.min(maxWidth / w, maxHeight / h, 1);
      h = Math.round(h * scale);
      w = Math.round(w * scale);

      const canvas = imgToCanvasWithOrientation(img, w, h);
      canvas.toBlob((blob) => {
        if (!blob) {
          resolve(file);
          return;
        }
        // eslint-disable-next-line no-bitwise
        console.log(`Resized image to ${ w }x${ h }, ${ blob ? blob.size >> 10 : undefined }kB`);
        const finalFile = new File([blob], '.jpg', { type: 'image/jpeg' });
        resolve(finalFile);
      }, 'image/jpeg', quality);
    };
    img.src = URL.createObjectURL(file);
  });

  return promise;
};

export default imageCompressor;
