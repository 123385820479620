export const getLocalstorageItem = (key: string) => localStorage.getItem(key);

export const getLocalstorageObject = <T>(key: string) => {
  const item = localStorage.getItem(key);
  try {
    if (item) {
      return JSON.parse(item) as T;
    }
  } catch (e) {
    return null;
  }
  return null;
};

export const setLocalstorageItem = (key: string, value: string) => {
  localStorage.setItem(key, value as string);
};

export const setLocalstorageObject = <T>(key: string, value: T) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const removeLocalstorageItem = (key: string) => {
  localStorage.removeItem(key);
};
